<template>
  <!-- 组件大图详情 -->
  <div class="widget_large_mask" v-show="showWidget" @click="hideWidget" @click.stop>
    <WidgetPopup
    :imageData="currentImageData"
    :similarData="similarWidget"
    v-show="showWidget"
    @similarWidget="showImages"
    @hide="hideWidget"/>
  </div>

  <div class="idea_large_main" @click.stop>
    <p @click="hideDetailsImage">[esc关闭]</p>
    <img
    src="../../assets/images/close_window.svg"
    @click="hideDetailsImage">
    <div class="idea_large_img">
      <img :src="props.imageUrl">
    </div>
    <!-- UI截图 -->
    <div class="idea_large_content" v-if="identifier === 'img'">
      <!-- logo和标题 -->
      <div class="idea_large_right">
        <div class="large_right_logo">
          <img :src="coverImage">
          <h6>{{ title }}</h6>
        </div>
      </div>
      <!-- 标签 -->
      <div class="idea_large_right">
        <span>标签</span>
        <div class="ui_tagsbox">
          <div
            @click="searchByKeyword(tag.name)"
            class="ui_tags_item"
            v-for="(tag, index) in tags"
            :key="index">
            {{ tag.name }}
          </div>
        </div>
        <span>相似组件 ( {{ similarWidgets.length }} ) Tips：复制到设计工具编辑 </span>
      </div>
      <!-- 相似组件 -->
      <div class="idea_large_widget">
          <div class="large_widget_list">
            <div
              class="large_widget_item"
              v-for="widget in similarWidgets"
              :key="widget._id"
              @click="showImages(widget._id)">
              <img :src="widget.image">
            </div>
          </div>
          <!-- 当相似组件为空 -->
          <div class="null_widget" v-if="similarWidgets.length === 0">
            <img src="../../assets/images/null.svg" />
            <p>暂无相似组件</p>
          </div>
      </div>
    </div>
    <!-- 设计思维 -->
    <div class="idea_large_content" v-else-if="identifier === 'div'">
      <!-- logo和标题 -->
      <div class="idea_large_right">
        <div class="large_right_logo">
          <img :src="coverImage">
          <h6>{{ title }}</h6>
        </div>
      </div>
      <div class="home_read_text">
        <!-- 功能分析 -->
        <div class="home_read_text_outline">
          <span>功能分析</span>
          <div class="home_read_text_content">
            <pre>{{ appUI.outline }}</pre>
          </div>
        </div>
        <!-- 广告 -->
        <div class="ad_box_read" @click="openAdLink(randomMarketAd.link)">
          <div class="ad_store_img">
            <img :src="randomMarketAd.image" />
          </div>
          <div class="ad_store_content">
            <span class="ad_store_title">{{ randomMarketAd.title }}</span>
            <span class="ad_store_price">到手价￥{{ randomMarketAd.price }}元</span>
            <button @click="copyMarketAdCode(randomMarketAd.code)" @click.stop>复制淘口令</button>
          </div>
          <span class="ad_identifier">由淘宝提供的广告</span>
        </div>
        <!-- 设计思维 -->
        <div class="home_read_text_analysis">
          <img src="../../assets/images/icon_analysis.svg" />
          <!-- 加密内容 -->
          <div class="home_read_content_lock" v-if="isLock">
            <img src="../../assets/images/icon_lock.svg" />
            <span v-if="isLoggedIn">限免内容将有限次数对用户开放，<br />
              今日可解锁次数剩余：{{ userInfo.pass }} 次
            </span>
            <span v-else>请登录后解锁查看！
            </span>
            <button @click="handleUnlock(props.appuiId)">解锁 ( 解锁后2小时内有效 )</button>
          </div>
          <!-- 设计思维 -->
          <div class="home_read_text_content">
            <pre>{{ appAnalysis }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted, onBeforeUnmount, provide, watch, defineProps } from 'vue'
import { get, post } from '../../utils/request'
import WidgetPopup from '../../components/WidgetPopup.vue'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  coverImage: {
    type: String
  },
  tags: {
    type: Array,
    required: true
  },
  similarWidgets: {
    type: Array,
    required: true
  },
  identifier: String,
  appuiId: {
    type: String
  }
})
const isLoggedIn = localStorage.getItem('isLogin') === 'true' // 登录状态
const route = useRoute()
const { userInfo } = useUserEffect()
const showAppImage = inject('showAppImage', ref(false)) // 接收父组件收藏状态
const showWidget = ref(false) // 组件弹窗
const currentImageData = ref(null) // 弹窗大图
const similarWidget = ref([]) // 相似组件数据
const isCollected = ref(false) // 收藏状态
provide('isCollected', isCollected) // 共享收藏状态给子组件
const router = useRouter() // 使用 useRouter 获取路由实例
const appUI = ref([]) // 功能分析
const appAnalysis = ref('') // 设计思维
const isLock = ref(true) // 加密
const randomMarketAd = ref([]) // 随机广告

// 关闭大图弹窗
const hideDetailsImage = async () => {
  showAppImage.value = false
  document.body.style.overflow = 'auto'
}
// 按esc键刚关闭弹窗
const handleKeyPress = (event) => {
  if (event.key === 'Escape' || event.key === 'Esc') {
    hideDetailsImage()
    document.body.style.overflow = 'auto'
  }
}

// 显示弹窗
const enterWidgetPopup = () => {
  showWidget.value = true
  if (showWidget.value) {
    document.body.style.overflow = 'hidden'
  } else {
    // 隐藏子组件后恢复滚动
    document.body.style.overflow = 'auto'
  }
}

// 隐藏弹窗
const hideWidget = async () => {
  showWidget.value = false // 子组件点击按钮隐藏后的回调
}

// 获取单个组件逻辑
const showImages = async (id) => {
  if (isLoggedIn) {
    const userId = userInfo._id
    isCollected.value = await checkIfCollected(userId, id)
  }
  try {
    const result = await get(`/api/widget/${id}`)
    if (result?.errno === 0 && result?.data) {
      currentImageData.value = result.data // 更新当前图片数据
      enterWidgetPopup() // 显示图片弹窗
      getWidgetByTags(id) // 按标签查询相似组件
    } else {
      console.error('获取失败:', result?.errmsg || '未知错误')
    }
  } catch (error) {
    console.error('获取失败:', error.message)
  }
}

// 按标签查询相似组件
const getWidgetByTags = async () => {
  const id = currentImageData.value._id
  const tags = currentImageData.value.tags
  const response = await post('/api/widget/search/tags', { tags, id })
  if (response?.errno === 0 && response?.data) {
    similarWidget.value = response.data
  }
}

// 检查当前数据是否已收藏
const checkIfCollected = async (userId, itemId) => {
  try {
    const response = await get(`/api/collect/check/${userId}/${itemId}`)
    if (response?.errno === 0 && response?.data) {
      return true
    }
  } catch (error) {
    console.error('检查是否收藏失败:', error.message)
  }
  return false
}

// 点击标签搜索
const searchByKeyword = (keyword) => {
  const route = {
    name: 'search',
    query: { type: 'widget', q: keyword }
  }
  document.body.style.overflow = 'auto'
  // 检查当前路由名称
  const currentRouteName = router.currentRoute.value.name

  if (['app', 'app-hot', 'screen', 'app-all', 'appdetails', 'read'].includes(currentRouteName)) {
    // 获取路由对象的完整 URL
    const url = router.resolve(route).href
    // 在新标签页中打开该 URL
    window.open(url, '_blank')
  } else {
    showAppImage.value = false
    // 默认跳转
    router.push(route)
  }
}

// 监听路由参数变化关闭截图弹窗
watch(() => route.query.q, (newQuery) => {
  if (newQuery) {
    showAppImage.value = false
  } else {
    window.location.reload()
  }
})

onMounted(() => {
  window.addEventListener('keydown', handleKeyPress)
  getReadUI()
  getRandomMarketAd()

  // 页面加载时检查本地缓存
  const detailImageId = props.appuiId
  const cachedData = getCache(`analysis_${detailImageId}`)
  if (cachedData) {
    appAnalysis.value = cachedData // 使用缓存数据
    isLock.value = false
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyPress)
})

// 查询功能分析
const getReadUI = async () => {
  const detailImageId = props.appuiId
  try {
    const result = await get(`/api/appui/readui/${detailImageId}`)
    if (result?.errno === 0) {
      appUI.value = result.data
    }
  } catch (error) {
    console.error(error)
  }
}

// 设置带有过期时间的数据
const setCache = (key, value, ttl) => {
  const now = new Date().getTime() // 当前时间戳
  const data = {
    value: value,
    expiry: now + ttl // 过期时间 = 当前时间 + ttl（缓存生存时间，毫秒数）
  }
  localStorage.setItem(key, JSON.stringify(data))
}

// 获取缓存数据并检查是否过期
const getCache = (key) => {
  const cachedData = localStorage.getItem(key)
  if (!cachedData) {
    return null // 没有缓存数据
  }

  const data = JSON.parse(cachedData)
  const now = new Date().getTime()

  // 检查是否过期
  if (now > data.expiry) {
    localStorage.removeItem(key) // 如果数据已过期，删除缓存
    return null
  }

  return data.value // 返回缓存数据
}

// 查询设计思维
const getAnalysis = async (detailImageId) => {
  const cacheKey = `analysis_${detailImageId}`
  try {
    const result = await get(`/api/appui/analysis/${detailImageId}`)
    if (result?.errno === 0) {
      appAnalysis.value = result.data
      // 将数据和过期时间一起存储到 localStorage
      setCache(cacheKey, result.data, 7200000) // 缓存时间 2 小时
    }
  } catch (error) {
    console.error(error)
  }
}

// 解锁逻辑
const handleUnlock = async () => {
  if (!isLoggedIn) {
    ElMessage.warning('请登录后解锁查看！')
    return
  }
  if (userInfo.pass <= 0) {
    ElMessage.error('解锁次数不足，请明天再来~')
    return
  }
  try {
    const userId = userInfo._id
    const appuiId = props.appuiId
    const response = await post('/api/user/pass', { userId, appuiId })
    if (response.success) {
      userInfo.pass -= 1
      await getAnalysis(appuiId)
      isLock.value = false
      ElMessage.success('内容已解锁~')
    } else {
      ElMessage.error('解锁失败，请稍后再试')
    }
  } catch (error) {
    console.error('解锁请求失败:', error)
  }
}

// 获取1条广告
const getRandomMarketAd = async () => {
  try {
    const result = await get('/api/market-ad/random')
    if (result?.errno === 0 && result?.data) {
      // 为广告添加 isAd 标识
      randomMarketAd.value = {
        ...result.data,
        isAd: true // 添加广告标识
      }
    }
  } catch (error) {
    console.error(error)
  }
}

// 复制口令
const copyMarketAdCode = async (code) => {
  try {
    await navigator.clipboard.writeText(code)
    ElMessage({
      showClose: true,
      message: '淘口令已复制，打开淘宝App即可自动识别！',
      type: 'success',
      duration: 5000 // 0为不自动关闭，默认值3000
    })
  } catch (error) {
    console.error('复制失败:', error)
    ElMessage.error('复制失败，请重试')
  }
}

// 跳转广告
const openAdLink = (link) => {
  window.open(link, '_blank') // 在新窗口中打开对应的链接
}

</script>

<style lang="scss">
@import '../../style/idea/idea.scss'
</style>
